/* --------------------------------------------------------------------------------------------------------------------------------------
------------------------------------------------------------------- Extra Page -----------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.extra-page{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #000;
    color: #fff;
    background-image: url('https://ik.imagekit.io/onceadev/assets/tr:f-auto/exatra-page-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.extra-page p{
    margin: 1.667vw 0;
    opacity: .69;
}

.extra-page svg{
    z-index: 0;
    position: absolute;
    width: 73.819vw;
    pointer-events: none;
}

.extra-page .pb{
    background-color: #fff;
    color: #000;
}

.extra-page .sb{
    filter: invert(1);
    margin-left: 1.667vw;
}

.extra-page .pb::before{
    background-color: #000;
}


.sub-page main{
    overflow: visible;
}

.sub-s2{
    padding: 8.333vw 0;
    border-top: 1px solid var(--opposite-border);
    background-color: var(--background-color);
    transition: all 300ms ease-out;
    min-height: 100vh;
}

.sub-page section {
    position:sticky;
    position:-webkit-sticky;
    top: 0;
}

.sub-heading{
    font-size: 1.389vw;
    display: block;
    line-height: 1.667vw;
    opacity: 0.6;
    text-transform: uppercase;
}

.sub-s2 h2, .sub-s2 h2 *{
    font-size: 3.333vw;
    line-height: 4.028vw;
}

.sub-s2 h2.line{
    height: 4.028vw;
}


/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Contact --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */


.contact-hero-wrapper{
    padding: 15.972vw 0 9.722vw 0;
}

.contact-form .cb{
    position: fixed;
    bottom: 4.167vw;
    right: 4.167vw;
    background-color: #fff;
    filter: var(--logo);
    border: 0px none !important;
    z-index: 2;
}



/* Contact Form */

.contact-form {
    position: relative;
    overflow: hidden;
}

.contact-form h4:not(.fromh4-1){
    margin: 3.333vw 0;
}

.contact-form form .p-i input, .contact-form textarea{
    outline: none;
    display: block;
    font-weight: 400;
    font-size: 1.389vw;
    line-height: 1.667vw;
    padding: 1.111vw 0;
    border: 0px;
    margin: 3.333vw 0;
    border-bottom: 1px solid var(--opposite-border);
    color: var(--color);
    background: transparent;
    min-width: 59.569vw;
}

.contact-form textarea{
    min-height: 8.333vw;
}

.contact-form .w-s, .contact-form .e-b {
    max-width: 59.569vw;
}

.contact-form label{
    display: inline-block;
    padding: 1.11vw 1.667vw;
    border-radius: 100px;
    font-size: 1.389vw;
    line-height: 1.667vw;
    margin: 0.833vw 0.417vw;
    cursor: pointer;
    filter: var(--svg);
}

.contact-form input[type="radio"], .contact-form input[type="checkbox"] {
    display: none;
} 

input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
    color: white;
    background-color: #000;
}

.contact-form .submit-button{
    filter: var(--svg);
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Services -----------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.services-hero-wrapper{
    padding: 15.972vw 0 9.722vw 0;
}

.services-section2 {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.services-section2 .s-all:not(.s-wwa){
    margin-top: 8.333vw;
}

.services-section2 .s-all span.s-nu{
    margin-bottom: 0.833vw;
}

.services-section2 .s-all h5{
    font-size: 1.389vw;
    line-height: 2.067vw;
    margin-top: 2.083vw;
}

.services-section2 .s-all .s-rows{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 4.167vw 0 calc(4.167vw - 1.736vw) 0;
}

.services-section2 .s-all .s-row{
    min-width: 42vw;
}

.services-section2 .s-all hr{
    margin: 1.736vw 0;
    color: var(--opposite-border);
    border: 0;
    border-top: 1px solid;
    opacity: 1;
}

.services-section2 .s-all .button{
    filter: var(--svg);
}

.contact-values  {
    padding-top: 8.333vw;
}

.contact-values .button {
    filter: var(--svg);
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Studio --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.studio-section2 h3, .studio-section2 h3 *{
    font-size: 2.222vw;
    line-height: 2.778vw;
}

.studio-hero-wrapper{
    padding: 15.972vw 0 2.083vw 0;
}

.studio-section2 .sub-s2{
    border-top: 0px solid var(--opposite-border);
}

.studio-section2 hr{
    border-top: 1px solid var(--opposite-border);
    margin: 0rem 0;
    opacity: 1;
}

.studio-section2 .button {
    filter: var(--svg);
}

.wwa-content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1.667vw;
    margin-bottom: 8.333vw;
}

.wwa-content p{
    opacity: 0.8;
}

.hwd-content{
    margin-top: 1.667vw;
    margin-bottom: 4.167vw;
}

.studio-hwd{
    margin: 8.333vw 0;
}

.hwd-p-cover{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.667vw 8.333vw 1.667vw 0;
    border-top: 1px solid var(--opposite-border);
}

.hwd-p-cover .left{
    align-items: flex-start;
}

.hwd-p-cover .right{
    min-width: 13.889vw;
    justify-content: flex-start;
}

.hwd-p-cover .right ul{
    margin: 0;
}

.hwd-p-cover span{
    font-size: 1.111vw;
    line-height: 1.667vw;
}

.hwd-p-cover h3{
    margin-left: 6.25vw;
}

.hwd-p-cover li{
    font-size: 1.111vw;
    line-height: 1.667vw;
}

.studio-hwd .button-cover{
    text-align: center;
    position: relative;
    overflow: hidden;
}

.studio-f-img{
    height: 100vh;
    background-image: url('https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-f-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
}

.s-values-1{
    margin: 8.333vw 0;
}

.studio-values-flex{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.studio-values-flex p, .studio-values .accordion-item p{
    font-size: 1.389vw;
    line-height: 2.222vw;
    margin-top: 1.667vw;
}

.studio-values-flex div{
    min-width: 43.056vw;
    flex-direction: column;
    align-items: flex-start;
}

.studio-values-flex1 div{
    margin-bottom: 4.167vw;
} 

.studio-career-cover{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.083vw 0;
    border-top: 1px solid var(--opposite-border);
}

.studio-faq *, .accordion-header, .accordion-button{
    outline: none !important;
}

.accordion-button:focus{
    outline: none !important;
    box-shadow: none;
}

.studio-career-cover.last{
    border-top: 1px solid var(--opposite-border);
    border-bottom: 1px solid var(--opposite-border);
}

.studio-faq .accordion-item {
    color: inherit;
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid var(--opposite-border);
    border-radius: 0px;
}

.studio-faq .accordion-button {
    padding: 1.667vw 0;
    color: inherit;
    background-color: transparent;
    border: 0;
    border-bottom: 0px solid var(--opposite-border);
}

.studio-faq .accordion-body {
    padding: 0 0 1.667vw 0;
}

.studio-faq .accordion-body p{
    opacity: 0.75;
}

.studio-faq .accordion-button::after{
    filter: var(--svg);
}

.studio-faq .accordion {
    --bs-accordion-btn-icon: url(https://ik.imagekit.io/onceadev/assets/tr:f-auto/plus1.svg);
    --bs-accordion-btn-icon-width: 1.25vw;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform .35s cubic-bezier(.25,.46,.45,.94);
    --bs-accordion-btn-active-icon: url(https://ik.imagekit.io/onceadev/assets/tr:f-auto/close1.svg);
}

.studio-faq .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: transparent;
    box-shadow: none !important;
}

/* Experimental */

.wwa-slider {
    position: relative;
    overflow: hidden;
  }
  
.images-container {
    display: flex;
    align-items: center;
    gap: 1.389vw;
    animation: ma 25s linear infinite;
}

.images-container img {
    flex-shrink: 0;
}

@keyframes ma {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-400%);
    }
}

.wwa-slider .image1 {
    aspect-ratio: 406/360;
    width: 28.194vw;
    height: 25vw;
}

.wwa-slider .image2 {
    aspect-ratio: 406/543;
    width: 28.194vw;
    height: 37.708vw;
}

  
/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Work --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.work-project .nav-tabs{
    border: 0px;
    margin-bottom: 2.167vw;
}

.work-project .button{
    filter: var(--svg);
    margin-right: 1.111vw;
}

.work-project .button.active{
    background-color: #000;
    color: #fff;
}

.work-flex{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.work-flex *{
    z-index: 1;
}

.work-flex a{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
}

.work-flex .inner-project{
    margin-top: 4.167vw;
    height: fit-content;
    position: relative;
    overflow: hidden;
    width: 43.056vw;
}

.work-flex .inner-project img{
    aspect-ratio: 620/570;
    height: 39.583vw;
    width: 43.056vw;
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Common --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */


.sub-loader{
    background: var(--background-color);
    color: var(--color);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 912;
    transform-origin: top;
    pointer-events: none;
}

.sub-loader h4 {
    color: var(--color);
    pointer-events: none;
    position: relative;
    overflow: hidden;
}

.sub-loader h4 span{
    display: inline-block;
    text-transform: uppercase;
}