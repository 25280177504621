/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Global --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
}


html, body{
    width: 100%;
    overscroll-behavior: none;
    scroll-behavior: initial !important;
    min-height: 100%;
    font-weight: 400 !important;
    cursor: none;
    font-family: Fractul-Regular, sans-serif !important;
}

[data-theme="light"] {
  --background-color: #fff !important;
  --opposite-background-color: #1b1b1b !important;
  --color: #2b2b2b !important;
  --opposite-color: #f0f0f0!important;
  --cursor: #000 !important;
  --opposite: #000 !important;
  --button-bg: #000 !important;
  --opposite-border: rgba(0, 0, 0, 0.3) !important;
  --logo: invert(1);
  --svg: invert(0);
  --header-color: #000 !important;
  --header-opposite-color: #fff!important;
  --bg-opacity: 0.2;
}

[data-theme="dark"] {
    --background-color: #1b1b1b !important;
    --opposite-background-color: #fff !important;
    --color: #f0f0f0 !important;
    --opposite-color: #2b2b2b!important;
    --cursor: #fff !important;
    --opposite: #fff !important;
    --button-bg: #fff !important;
    --opposite-border: rgba(255, 255, 255, 0.3) !important;
    --logo: invert(0);
    --svg: invert(1);
    --header-color: #fff !important;
    --header-opposite-color: #000!important;
    --bg-opacity: 0.2;
}


::-webkit-scrollbar{
    display: none;
}

main{
  position: relative;
  overflow: hidden;
  margin-bottom: -4vw;
  background: var(--background-color) !important;
  color: var(--color) !important;
  z-index: 2;
  transition: all 300ms ease-out;
}

.error{
  color: red;
  display: block;
}

i{
  font-style: normal !important;
}

.bg-noise {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200vw;
    height: 200vh;
    background: transparent url('https://ik.imagekit.io/onceadev/assets/tr:f-auto/bg.png') repeat 0 0;
    background-repeat: repeat;
    /* animation: bg-animation 1s infinite; */
    opacity: var(--bg-opacity);
    visibility: visible;
    pointer-events: none;
    z-index: 0;
}

.container{
    padding-left: 4.167vw;
    padding-right: 4.167vw;
    max-width: 100%;
}

.desk-br{
  display: none;
}

.tab-br{
  display: none;
}

.mob-br{
  display: none;
}

.line{
  position: relative;
  overflow: hidden;
  margin: 0 !important;
}

.line span{
  position: absolute;
  margin: 0;
  padding: 0;
}

h1, h1 *{
    font-size: 8.333vw; /* 120px */
    line-height: 10.417vw; /* 150px */
    margin: 0;
    padding: 0;
}

h1 .line{
  height: 10.417vw; /* 150px */
}

h2, h2 *{
    font-size: 5.556vw; /* 80px */
    line-height: 6.944vw; /* 100px */
    margin: 0;
    padding: 0;
}

h2 .line{
  height: 6.944vw; /* 100px */
}

h3, h3 *{
    font-size: 3.889vw; /* 56px */
    line-height: 4.861vw; /* 70px */
    margin: 0;
    padding: 0;
}

h3 .line{
  height: 4.861vw; /* 70px */
}

h4, h4 *{
    font-size: 2.778vw; /* 40px */
    line-height: 3.472vw; /* 50px */
    margin: 0;
    padding: 0;
}


h5, h5 *{
    /* Not Final */
    font-size: 2.222vw; /* 32px */
    line-height: 4.167vw;
    margin: 0;
    padding: 0;
}

p, p *{
    font-size: 1.667vw; /* 24px */
    line-height: 2.222vw; /* 32px */
    margin: 0;
    padding: 0;
}

.extra{
    /* Not Final */
    font-size: 1.111vw; /* 16px */
    line-height: 1.944vw;
}

.left{
    display: flex;
    justify-content: left;
    align-items: center;
}

.left.desk-br{
  display: flex;
}

.right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.right.desk-br{
  display: flex;
}

.middle{
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle.desk-br{
  display: flex;
}
.row.desk-br{
  display: flex;
}

.button{
  display: inline-block;
  background: transparent;
  border: none;
  z-index: 1;
  padding: 1.11vw 1.667vw;
  text-align: center;
  vertical-align: middle;
  position: relative;
  overflow:hidden;
  border: 1px solid #000;
  border-radius: 2.778vw;
  text-decoration: none;
  color: #000;
  font-size: 1.389vw;
  line-height: 1.667vw;
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}
.button:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 100px;
  background-color: #000;
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}
.button:hover {
  color: #fff;
  border: 1px solid transparent;
}
.button:hover:before {
  top: auto;
  bottom: 0;
  height: 100%;
}
.button span::after {
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	color: #fff;
  font-weight: inherit;
  font-size: inherit;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.button span {
	display: block;
  position: relative;
  overflow: hidden;
}

.button span > i {
	display: block;
  position: relative;
}
.button span::after,
.button span > i {
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transition-timing-function: cubic-bezier(.215,.61,.355,1);
	transition-timing-function:cubic-bezier(.215,.61,.355,1);
}
.button:hover span::after {
	opacity: 1;
  color: #fff;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.button:hover > span i {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}



/* cursor */
.circle {
    height: 1.667vw;
    width: 1.667vw;
    max-width: 32px;
    max-height: 32px;
    border-radius: 1.667vw;
    background-color: var(--cursor);
    position: fixed; 
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99999999; 
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Header --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.header-p{
  cursor: auto;
}

header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 911;
    padding: 1.111vw 0;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}


header .left svg{
    width: 10.237vw;
    height: auto;
}

header .middle a{
    font-size: 1.111vw;
    line-height: 1.736vw;
    margin-left: 2.222vw;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s ease,transform 0.5s ease,-webkit-transform 0.5s ease;
}

.sub-page header .left svg{
  filter: var(--logo);
}

.sub-page header .middle a{
  color: var(--header-color);
}

.sub-page header .middle a::after {
	color: var(--header-color);
}

.work-page header .left svg{
  filter: var(--logo);
}

.work-page header .middle a{
  color: var(--header-color);
}

.work-page header .middle a::after {
	color: var(--header-color);
}

header .middle a { 
	display: inline-block;
	position: relative;
  overflow: hidden;
  font-size: 1.111vw;
  line-height: 1.736vw;
  margin-left: 2.222vw;
  text-transform: uppercase;
  text-decoration: none;
}


header .middle a::after {
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}


header .middle a > span {
	display: block;
}

header .middle a::after,
header .middle a > span {
	-webkit-transition: -webkit-transform 0.6s, opacity 0.3s;
	transition: transform 0.6s, opacity 0.3s;
	-webkit-transition-timing-function: cubic-bezier(.215,.61,.355,1);
	transition-timing-function:cubic-bezier(.215,.61,.355,1);
}
header .middle a:hover::after {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
header .middle a:hover > span {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

.mode-switch .switch{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.833vw 1.528vw;
  border: 1px solid var(--opposite-border);
  margin-right: 1.111vw;
  border-radius: 3.667vw;
  min-width: 8.333vw;
}

.mode-switch .switch:hover{
  border: 1px solid transparent;
}

.mode-switch img{
  width: 1.667vw;
  height: 1.667vw;
  transition: all 0.5s cubic-bezier(.215,.61,.355,1);
}

.mode-switch .switch{
  position: relative;
  overflow: hidden;
}

.mode-switch .switch span{
    color: var(--header-color);
    font-size: 1.111vw;
    line-height: 1.736vw;
    margin-right: 0.556vw;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s ease,transform 0.5s ease,-webkit-transform 0.5s ease;
}

.mode-switch .switch::before{
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 100px;
  background-color: var(--opposite-background-color);
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}
header .mode-switch .switch:hover::before{
  top: auto;
  bottom: 0;
  height: 100%;
}

header .button span::after {
  color: var(--header-opposite-color) !important;
}

header .header-btn {
  padding: 0.833vw 1.528vw;
  border: 1px solid var(--opposite-border);
  margin-right: 1.111vw;
  border-radius: 3.667vw;
  color: var(--opposite);
  font-size: 1.111vw;
  line-height: 1.736vw;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

header .header-btn:hover {
  border: 1px solid transparent;
}

header .header-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 100px;
  background-color: var(--opposite-background-color);
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}

header .header-btn:hover span::after{
  color: var(--header-opposite-color);
}

header .header-btn:hover::before{
  top: auto;
  bottom: 0;
  height: 100%;
}

.headerdown{
  box-shadow: inset 0px -44px 76px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(86px) !important;
  -webkit-backdrop-filter: blur(86px) !important;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
}

.scroller{
  position: absolute;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.custom-image{
  width: 9vw;
  position: absolute;
  bottom: 1.5vw;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  display: none;
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Footer ------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */


footer{
  position: relative;
  overflow: hidden;
  color: #fff;
  margin: 0;
  padding: 0;
}

footer .bg{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

footer .bg img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
footer .content{
  position: relative;
  overflow: hidden;
  margin: 8.167vw 0 4.167vw 0; /* 4.167 + 4 on top because of the main overlay */
}

footer .cardsbase-cover{
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: flex-end;
}

footer .cardsbase{
  display: flex;
}

footer .contactcard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.667vw;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1.389vw;
  width: 17.917vw;
  height: 25.999vw;
  text-decoration: none;
  color: #fff;
  margin: 0 0.833vw 1.667vw 0.833vw;
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
  position: relative;
  overflow: hidden;
}

footer .cardsbase .contactcard:last-child{
  margin-right: 0;
}

footer .contactcard svg{
  width: 3.999vw;
  height: 3.999vw;
}

footer .contactcard .cta{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .contactcard .cta h5{
  margin: 0;
  font-size: 1.389vw;
  line-height: 1.667vw;
}

footer .contactcard .cta svg{
  width: 3.333vw;
  height: 3.333vw;
}

footer .contactcard:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 1.389vw;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}
footer .contactcard:hover {
  color: #fff;
  filter: invert(1);
}
footer .contactcard:hover:after {
  top: auto;
  bottom: 0;
  filter: invert(1);
  height: 100%;
}

footer .socials p{
  font-size: 1.667vw;
  line-height: 2.222vw;
  margin: 1.389vw 0 2.778vw 0;
}

footer .socials h4{
  font-size: 2.222vw;
  line-height: 2.778vw;
  margin-bottom: 1.389vw;
}

footer .socials a{
  font-size: 1.111vw;
  line-height: 1.667vw;
  text-decoration: none;
  color: #fff;
  margin-right: 1.667vw;
}

footer .copyrights{
  border-top: 1px solid rgba(255, 255, 255, 0.65);
  padding: 2.083vw 4.167vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

footer .socials .button{
  padding: 0;
  border: 0;
}

footer .socials .button::before{
  filter: invert(1);
}

footer .socials svg{
  width: 3.999vw;
  height: 3.999vw;
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}

footer .socials svg *{
  transition: all 0.3s cubic-bezier(.215,.61,.355,1);
}

/* footer .socials svg:hover rect{
  fill: white;
} */

footer .socials svg:hover path{
  fill: black;
}

footer .copyrights svg{
  width: 8.639vw;
  height: 2.083vw;
}

footer span{
  display: block;
  font-size: 1.111vw;
  line-height: 1.389vw;
}

footer .cardsbase{
  padding-left: calc(var(--bs-gutter-x) * .5);
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Keyframes --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@keyframes bg-animation {
    0% {
        transform: translate(0,0)
    }

    10% {
        transform: translate(-5%,-5%)
    }

    20% {
        transform: translate(-10%,5%)
    }

    30% {
        transform: translate(5%,-10%)
    }

    40% {
        transform: translate(-5%,15%)
    }

    50% {
        transform: translate(-10%,5%)
    }

    60% {
        transform: translate(15%,0)
    }

    70% {
        transform: translate(0,10%)
    }

    80% {
        transform: translate(-15%,0)
    }

    90% {
        transform: translate(10%,5%)
    }

    100% {
        transform: translate(5%,0)
    }
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Desktop --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (min-width: 992px) {


  .desk-br{
    display: block;
  }
  
  .tab-br{
    display: none;
  }
  
  .mob-br{
    display: none;
  }

}


/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Tabs --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 991px) {

  body{
    cursor: pointer;
  }

  /* main {
    margin-bottom: 0vw;
  } */

  h1, h1 *{
    font-size: 10.215vw; /* 76px - 744 */
    line-height: 14.785vw; /* 110px - 744 */
    margin: 0;
    padding: 0;
  }

  h1 .line{
    height: 14.785vw; /* 110px - 744 */
  }

  h2, h2 *{
      font-size: 8.602vw; /* 64px - 744 */
      line-height: 10.753vw; /* 80px - 744 */
      margin: 0;
      padding: 0;
  }

  h2 .line{
    height: 10.753vw; /* 80px - 744 */
  }

  h3, h3 *{
      font-size: 6.452vw; /* 48px - 744 */
      line-height: 8.065vw; /* 60px -744 */
      margin: 0;
      padding: 0;
  }

  h3 .line{
    height: 8.065vw; /* 60px - 744 */
  }

  h4, h4 *{
    font-size: 4.301vw; /* 32px - 744 */
    line-height: 5.645vw; /* 42px -744 */
    margin: 0;
    padding: 0;
  }

  h4 .line{
    height: 5.645vw; /* 42px -744 */
  }


  p, p *{
      font-size: 2.688vw; /* 20px - 744*/
      line-height: 3.763vw; /* 28px - 744 */
      margin: 0;
      padding: 0;
  }

  .button{
    padding: 2.151vw 3.091vw;
    border-radius: 4.032vw;
    text-decoration: none;
    color: #000;
    font-size: 2.419vw;
    line-height: 2.957vw;
  }

  .container{
      padding-left: 4.032vw;
      padding-right: 4.032vw;
      max-width: 100%;
  }
  
  .circle{
    display: none;
  }

  .desk-br{
    display: none;
  }
  .mob-br{
    display: none;
  }
  
  .tab-br{
    display: block;
  }
  

  /* Header */

  .middle.desk-br{
    display: none;
  }
  .row.desk-br{
    display: none;
  }
  .left.desk-br{
    display: none;
  }
  .right.desk-br{
    display: none;
  }
  
  header{
      padding: 30px 0;
  }
  
  header .left svg{
      width: 17.742vw;
      height: auto;
  }

  .mode-switch .switch {
    padding: 1.613vw 2.016vw;
    margin-right: 1.075vw;
    border-radius: 3.226vw;
  }

  .mode-switch .switch span {
    font-size: 2.151vw;
    line-height: 2.554vw;
    margin-right: 1.075vw;
  }

  .mode-switch img {
    width: 3.226vw;
    height: 3.226vw;
}

  svg.menu{
    width: 6.845vw;
    height: 6.845vw;
    filter: var(--logo);
  }

  .menu-box{
    min-width: 34.914vw;
    background-color: #fff;
    color: #000;
    padding: 20px 2.032vw 4.032vw 4.032vw;
    position: fixed;
    top: 20px;
    right: 4.032vw;
    transform-origin: top right;
    transform: scale(0);
    z-index: 999;
    display: flex;
    flex-direction: column;
    border: 1px solid #F2F2F2;
    border-radius: 4.032vw;
  }

  .menu-box ul li{
    list-style: none;
    font-size: 2.688vw;
    line-height: 3.226vw;
    margin-top: 3.226vw;
    text-transform: uppercase;
  }

  .menu-box ul{
    padding: 0;
  }

  .menu-box ul a{
    text-decoration: none;
    color: #1b1b1b;
  }

  .menu-box .menu-close{
    text-align: right;
    align-self: flex-end;
    filter: invert(1);
  }

  .custom-image{
    width: 20vw;
    bottom: 6vw;
  }


  /* Footer */

  footer .content{
    margin: 13.441vw 0 6.72vw 0;
  }


  footer .contactcard{
    padding: 3.226vw;
    border-radius: 2.151vw;
    width: 43.952vw;
    height: 50.269vw;
    margin: 0 2.016vw 4.032vw 2.016vw;
  }


  footer .contactcard svg{
    width: 7.796vw;
    height: 7.796vw;
  }


  footer .contactcard .cta h5{
    margin: 0;
    font-size: 2.688vw;
    line-height: 3.226vw;
  }

  footer .contactcard .cta svg{
    width: 6.452vw;
    height: 6.452vw;
  }

  footer .contactcard:after {
    border-radius: 2.151vw;
  }

  footer .socials{
    margin-bottom: 6.72vw;
  }

  footer .socials p{
    font-size: 3.226vw;
    line-height: 4.032vw;
    margin: 3.226vw 0 6.452vw 0;
  }

  footer .socials h4{
    font-size: 4.301vw;
    line-height: 5.376vw;
    margin-bottom: 3.226vw;
  }

  footer .socials a{
    font-size: 2.151vw;
    line-height: 3.226vw;
    margin-right: 3.226vw;
  }

  footer .copyrights{
    padding: 4.032vw;
  }

  footer .socials svg{
    width: 7.796vw;
    height: 7.796vw;
  }

  footer .copyrights svg{
    width: 16.801vw;
    height: 3.898vw;
  }

  footer span{
    display: block;
    font-size: 2.151vw;
    line-height: 2.688vw;
  }

  footer .cardsbase-cover {
    align-items: flex-start;
}

  footer .cardsbase .contactcard:nth-child(1){
    margin-left: 0;
  }
  
}


/* --------------------------------------------------------------------------------------------------------------------------------------
-------------------------------------------------------------- Mobiles ------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 743px) {

  h1, h1 *{
    font-size: 11.282vw; /* 44px - 390 */
    line-height: 14.359vw; /* 56px - 390 */
    margin: 0;
    padding: 0;
  }

  h1 .line{
    height: 14.359vw; /* 56px - 390 */
  }

  h2, h2 *{
      font-size: 8.205vw; /* 32px - 390 */
      line-height: 10.769vw; /* 42px - 390 */
      margin: 0;
      padding: 0;
  }

  h2 .line{
    height: 10.769vw; /* 42px - 390 */
  }

  h3, h3 *{
      font-size: 7.179vw; /* 28px - 390 */
      line-height: 9.231vw; /* 36px -390 */
      margin: 0;
      padding: 0;
  }

  h3 .line{
    height: 9.231vw; /* 36px - 390 */
  }

  h4, h4 *{
    font-size: 5.528vw; /* 21.5px - 390 */
    line-height: 7.179vw; /* 28px -390 */
    margin: 0;
    padding: 0;
  }

  h4 .line{
    height: 7.179vw; /* 28px -390 */
  }


  p, p *{
      font-size: 4.103vw; /* 16px - 390*/
      line-height: 6.154vw; /* 24px - 390 */
      margin: 0;
      padding: 0;
  }

  .button{
    padding: 4.103vw 6.154vw;
    border-radius: 7.692vw;
    text-decoration: none;
    font-size: 4.103vw;
    line-height: 5.128vw;
  }

  .container{
      padding-left: 5.128vw;
      padding-right: 5.128vw;
      max-width: 100%;
  }

  .desk-br{
    display: none;
  }
  
  .tab-br{
    display: none;
  }
  
  .mob-br{
    display: block;
  }

  /* Header */

  header{
    padding: 20px 0;
  }

  header .left svg{
    width: 32.051vw;
    height: auto;
  }

  .mode-switch .switch {
    padding: 3.077vw;
    margin-right: 2.564vw;
    border-radius: 5.385vw;
  }

  .mode-switch .switch span {
    font-size: 3.59vw;
    line-height: 4.103vw;
    margin-right: 1.538vw;
  }

  .mode-switch img {
    width: 4.615vw;
    height: 4.615vw;
  }

  svg.menu{
    width: 11.569vw;
    height: 11.569vw;
  }

  .menu-box{
    min-width: 47.179vw;
    background-color: #fff;
    color: #000;
    padding: 10px 4.032vw 6.154vw 6.154vw;
    position: fixed;
    top: 20px;
    right: 4.032vw;
    transform-origin: top right;
    transform: scale(0);
    z-index: 999;
    display: flex;
    flex-direction: column;
    border: 1px solid #F2F2F2;
    border-radius: 6.154vw;
  }

  .menu-box ul li{
    list-style: none;
    font-size: 4.103vw;
    line-height: 4.872vw;
    margin-top: 4.872vw;
    text-transform: uppercase;
  }

  .menu-box ul{
    padding: 0;
  }

  .menu-box ul a{
    text-decoration: none;
    color: #1b1b1b;
  }

  .menu-box .menu-close{
    text-align: right;
    transform: rotate(45deg);
    align-self: flex-end;
    filter: invert(1);
  }

  .custom-image{
    width: 35vw;
    bottom: 14vw;
  }

  /* Footer */

  footer .content{
    margin: 20.513vw 0 15.385vw 0;
  }


  footer .contactcard{
    padding: 3.077vw;
    border-radius: 2.564vw;
    width: 42.308vw;
    height: 53.846vw;
    margin: 0 2.564vw 5.128vw 2.564vw;
  }


  footer .contactcard svg{
    width: 10.256vw;
    height: 10.256vw;
  }


  footer .contactcard .cta h5{
    margin: 0;
    font-size: 3.59vw;
    line-height: 4.872vw;
  }

  footer .contactcard .cta svg{
    width: 6.154vw;
    height: 6.154vw;
  }

  footer .contactcard:after {
    border-radius: 2.564vw;
  }

  footer .socials{
    margin-bottom: 12.308vw;
  }

  footer .socials p{
    font-size: 4.103vw;
    line-height: 4.872vw;
    margin: 6.154vw 0 10.256vw 0;
  }

  footer .socials h4{
    font-size: 6.667vw;
    line-height: 8.718vw;
    margin-bottom: 6.154vw;
  }

  footer .socials a{
    font-size: 4.103vw;
    line-height: 6.154vw;
    margin-right: 6.154vw;
  }

  footer .copyrights{
    padding: 5.128vw;
  }

  footer .socials svg{
    width: 10.256vw;
    height: 10.256vw;
  }

  footer .copyrights svg{
    width: 25.641vw;
    height: 6.154vw;
    margin-bottom: 3.077vw;
  }
  

  footer span{
    display: block;
    font-size: 3.077vw;
    line-height: 3.59vw;
  }

  footer .copyrights{
    display: block;
  }

}

/* Experimental */

.word-mark {
  overflow: hidden;
}

@keyframes hideWordMark {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes showWordMark {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.word-mark-path {
  animation-fill-mode: forwards;
}

.word-mark-path.hide {
  animation: hideWordMark 0.5s ease;
}

.word-mark-path.show {
  animation: showWordMark 0.5s ease;
}
