[data-theme="light"] {
    --dl-bg: #EDEDED;
    --cg-bg: #EDEDED;
    --yd-bg: #EDEDED;
}

[data-theme="dark"] {
    --dl-bg: #2B2B2B;
    --cg-bg: #2B2B2B;
    --yd-bg: #2B2B2B;
}


.work-page .sub-head{
    font-size: 1.389vw;
    margin-bottom: 1.667vw;
    display: block;
}

.work-page h1{
    font-size: 3.333vw;
    line-height: 4vw;
    text-transform: capitalize;
}

.work-page h1 .line{
    height: 4.3vw;
}

.work-page .hero-content{
    margin-top: 14.931vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4.083vw;
    text-align: center;
}

.work-page .work-hero-img-div{
    position: relative;
    overflow: hidden;
    height: 48.611vw;
    border-radius: 0.833vw;
}

.work-page .work-page-hi{
    margin: -2.944vw 0;
    width: 100%;
    height: 62.5vw;
}

.work-page .work-inner-container{
    margin: 8.333vw 12.5vw;
}

.work-page .work-inner-container p{
    opacity: 0.75;
}

.work-page .project-details{
    margin-top: 3.333vw;
}

.work-page .project-details .pd-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.667vw 0;
    border-top: 1px solid var(--opposite-border);
}

.work-page .project-details .pd-flex.last{
    border-bottom: 1px solid var(--opposite-border);
}

.work-page .project-details h6{
    font-size: 1.389vw;
    line-height: 2.222vw;
    margin: 0;
}

.work-page .mock{
    margin: 8.333vw 0;
    position: relative;
    z-index: 2;
}

.work-page .mock .full-mockimg{
    transition: all 300ms ease-out;
    border-radius: 0.833vw;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vw;
}

.work-page .mock .mock-flex{
    display: flex;
    flex-direction: row;
    gap: 1.389vw;
    justify-content: space-between;
}

.work-page .mock1 .full-mockimg{
    margin-bottom: 1.389vw;
}

.work-page .mock2 .full-mockimg{
    margin-top: 1.389vw;
}

.work-page .mock .half-mockimg{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-out;
    border-radius: 0.833vw;
    position: relative;
    overflow: hidden;
    width: 49.305vw;
    height: 50vw;
}

.work-page .related{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 8.333vw 0;
}

.work-page .related span{
    margin-bottom: 1vw;
}

.work-page .related h5{
    opacity: 0.8;
    margin-bottom: 1.667vw;
    font-size: 3.333vw;
    line-height: 4vw;
}

.work-page .related a{
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.work-page .overflow-image{
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid var(--opposite-border);
    width: 100%;
}

.work-page .overflow-image img{
    height: 300px;
    object-fit:cover;
    object-position: top;
    transition: all 0.7s cubic-bezier(0.5, 0.5, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 200.776, 0, 1);
}

.work-page .related:hover .overflow-image img{
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10.776, 0, 1);
}




/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------- DESIGNLANE --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.designlane .mock .full-mockimg{
    background-color: var(--dl-bg);
}


.designlane .mock .half-mockimg{
    background-color: var(--dl-bg);
}

.designlane .mock .half-mockimgin{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
}

.designlane .full-tab{
    width: 58.743vw;
    height: auto;
    aspect-ratio: 845.9/611;
}

.designlane .half-tab{
    width: 37.552vw;
    height: auto;
    aspect-ratio: 540.75/390.59;
}

.designlane .half-mob{
    width: 22.778vw;
    height: auto;
    aspect-ratio: 328/664;
}

.designlane .full-length{
    width: 100%;
    height: auto;
    aspect-ratio: 1260/391;
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------- COINGUARD --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.coinguard .mock .full-mockimg{
    background-color: var(--cg-bg);
}


.coinguard .mock .half-mockimg{
    background-color: var(--cg-bg);
}

.coinguard .mock .half-mockimgin{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
    background-color: var(--cg-bg);
    border-radius: 0.833vw;
}

.coinguard .threemorl{
    width: 72.569vw;
    height: auto;
    aspect-ratio: 1045/662.77;
}

.coinguard .widget{
    width: 31.076vw;
    height: auto;
    aspect-ratio: 447.5/450;
}

.coinguard .mobile1{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
}

.coinguard .half-mob{
    width: 49.305vw;
    aspect-ratio: 620/720;
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------- YUGA DECORS --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.yugadecors .mock .full-mockimg{
    background-color: var(--yd-bg);
}


.yugadecors .mock .half-mockimg{
    background-color: var(--yd-bg);
}

.yugadecors .mock .half-mockimgin{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
    background-color: var(--yd-bg);
    border-radius: 0.833vw;
}

.yugadecors .mobile1{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
}

.yugadecors .big{
    width: 100%;
    height: 50vw;
    aspect-ratio: 1260/720;
}

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------- BOOKING VISTA --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

.bookingvista .mock .full-mockimg{
    background-color: var(--yd-bg);
}


.bookingvista .mock .half-mockimg{
    background-color: var(--yd-bg);
}

.bookingvista .mock .half-mockimgin{
    width: 49.305vw;
    height: 50vw;
    aspect-ratio: 620/720;
    background-color: var(--yd-bg);
    border-radius: 0.833vw;
}

.bookingvista .mobile1{
    width: 49.305vw;
    aspect-ratio: 620/720;
}

.bookingvista .big{
    width: 100%;
    aspect-ratio: 1260/720;
}