/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Tabs --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 991px) {

  .heading {
    margin-bottom: 6.72vw;
  }

/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Hero --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

  .hero .h1-wrapper{
      padding: 30.538vw 0 25.538vw 0;
      min-height: auto;
  }

  .hero .marquee-parent{
      height: 13.441vw;
  }

  .hero .marquee-e{
      line-height: 8.065vw;
  }


  .hero .third{
      margin: 6.72vw 0;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- About --------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .home-about{
    margin: 13.441vw 0;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------- What we do ------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .whatwedotm{
    display: block;
  }

  .whatwedotm .w-card{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5.376vw 4.032vw;
    border-top: 1px solid var(--opposite-border);
  }

  .whatwedotm:last-child{
    border-bottom: 1px solid var(--opposite-border);
  }

  .whatwedotm .w-card a{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    left: 0;
  }

  .whatwedotm .w-card h4{
    padding: 0vw;
  }

  .whatwedotm svg{
    width: 5.376vw;
  }

  .whatwedotm svg{
    filter: var(--svg);
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Work Lead ----------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .work-lead{
    margin: 13.441vw 0;
  }

  .work-lead-cont{
    margin: 0vw 4.032vw;
    border: 1px solid var(--opposite-border);
    border-radius: 2.151vw;
    padding: 8.065vw 0;
  }

  .work-lead .hand{
    margin-top: 8.065vw;
    margin-bottom: 5.376vw;
    width: 78.763vw;
    height: 107.258vw;
  }

  .work-lead p{
    position: relative;
    bottom: 0;
    right: 0;
    text-align: center;
    margin-bottom: 4.376vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Work --------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .work{
    margin: 13.441vw 0;
  }

  .projects .project2{
    margin-top: 6.72vw;
  }

  .projects .project3{
    margin-top: 6.72vw;
  }

  .projects .project4{
    margin-top: 6.72vw;
  }

  .projects .work-bg{
    width: 80vw;
    height: 80vw;
  }

  .projects .project1 img, .projects .project4 img{
    width: 100%;
    height: 100%;
    border-radius: 2.151vw;
    aspect-ratio: auto;
    object-fit: cover;
  }

  .projects .project2 img, .projects .project3 img{
    width: 100%;
    height: 100%;
    border-radius: 2.151vw;
    aspect-ratio: auto;
    object-fit: cover;
  }

  .project-border{
    margin-top: 2.688vw;
    padding-top: 2.688vw;
  }

  .project-border h5{
    font-size: 2.688vw;
    line-height: 3.226vw;
    margin-top: 1.613vw;
  }

  .work .work-cta{
    margin-top: 6.72vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  ---------------------------------------------------------- Testimonial Lead -------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .testimonial-lead{
    margin: 13.441vw 0;
    overflow: visible;
  }

  .testimonial-lead-cont{
    margin: 0vw 4.032vw;
    padding: 13.441vw 4.032vw;
    border-radius: 2.151vw;
  }


  .testimonial-lead .top-hand{
    width: 24.328vw;
    height: 29.301vw;
    position: absolute;
    top: 0;
    right: 0;
  }

  .testimonial-lead .bottom-hand{
    width: 24.328vw;
    height: 28.898vw;
    bottom: -11.032vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Testimonial -------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .testimonials{
    margin: 13.441vw 0;
  }

  .testimonials .testimonial-each{
    margin: 13.441vw 0 0 0;
  }

  .testimonial-each h5{
    font-size: 2.688vw;
    line-height: 3.226vw;
    margin-top: 3.226vw;
    margin-bottom: 1.075vw;
  }

  .testimonial-each h6{
    font-size: 2.151vw;
    line-height: 2.554vw;
    margin: 0;
    opacity: 0.7;
  }


}

@media (min-width: 744px){
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

/* --------------------------------------------------------------------------------------------------------------------------------------
-------------------------------------------------------------- Mobiles ------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 743px) {

  .heading {
    margin-bottom: 15.385vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Hero --------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .hero .h1-wrapper{
      margin: 48.718vw -7.692vw 28.205vw 0;
      padding: 0;
      min-height: auto;
  }

  .hero .marquee-parent{
      height: 20.513vw;
  }

  .hero .marquee-e{
      line-height: 9.744vw;
  }


  .hero .third{
      margin: 12.821vw 0;
  }

  .hero .third p{
    margin-bottom: 6.154vw;
  }

  .hero .third .right{
    justify-content: flex-start;
  }
  
  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- About --------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .home-about{
    margin: 25.641vw 0;
  }

    /* --------------------------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------- What we do ------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .whatwedotm{
    display: block;
  }

  .whatwedotm .w-card{
    padding: 10.256vw 5.128vw;
  }

  .whatwedotm svg{
    width: 8.205vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Work Lead ----------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .work-lead{
    margin: 25.641vw 0;
  }

  .work-lead-cont{
    margin: 0vw 5.128vw;
    border-radius: 3.077vw;
    padding: 10.256vw 5.128vw;
  }

  .work-lead-cont h4{
    -webkit-text-stroke: 1px var(--opposite-border);
  }

  .work-lead .hand{
    margin-top: 10.256vw;
    margin-bottom: 5.128vw;
    width: 100%;
    height: auto;
  }

  .work-lead p{
    position: relative;
    bottom: 0;
    right: 0;
    text-align: center;
    margin-bottom: 4.376vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Work --------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .work{
    margin: 25.641vw 0;
  }

  .projects .project2{
    margin-top: 15.385vw;
  }

  .projects .project3{
    margin-top: 15.385vw;
  }

  .projects .project4{
    margin-top: 15.385vw;
  }

  .projects .work-bg{
    width: 80vw;
    height: 80vw;
  }

  .project-border{
    margin-top: 3.077vw;
    padding-top: 3.077vw;
  }

  .project-border h5{
    font-size: 3.333vw;
    line-height: 4.359vw;
    margin-top: 1.538vw;
  }

  .work .work-cta{
    margin-top: 10.385vw;
    align-items: flex-start;
  }

    /* --------------------------------------------------------------------------------------------------------------------------------------
  ---------------------------------------------------------- Testimonial Lead -------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .testimonial-lead{
    margin: 25.641vw 0;
  }

  .testimonial-lead-cont{
    margin: 0vw 5.128vw;
    padding: 15.385vw 5.128vw;
    border-radius: 3.077vw;
  }


  .testimonial-lead .top-hand{
    width: 34.103vw;
    height: auto;
    position: absolute;
    top: -12.821vw;
    right: 0;
  }

  .testimonial-lead .bottom-hand{
    width: 32.564vw;
    height: auto;
    bottom: -12.821vw;
  }

  /* --------------------------------------------------------------------------------------------------------------------------------------
  --------------------------------------------------------------- Testimonial -------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */

  .testimonials{
    margin: 25.641vw 0;
  }

  .testimonials .testimonial-each{
    margin: 15.385vw 0 0 0;
  }

  .testimonial-each h5{
    font-size: 4.615vw;
    line-height: 6.154vw;
    margin-top: 4.103vw;
    margin-bottom: 2.051vw;
  }

  .testimonial-each h6{
    font-size: 3.59vw;
    line-height: 4.359vw;
  }


}

