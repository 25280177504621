/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Tabs --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 991px) {

    .sub-s2 h2, .sub-s2 h2 * {
        font-size: 5.376vw;
        line-height: 6.452vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------- Extra Page -----------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .extra-page p{
        margin: 3.226vw 0 4.226vw 0;
    }

    .extra-page .sb{
        margin-left: 3.226vw;
    }

    .extra-page svg{
        width: 85vw;
    }
    
    .sub-s2{
        padding: 13.441vw 0;
    }

    .sub-heading{
        font-size: 2.688vw;
        line-height: 3.763vw;
    }
    
    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Contact --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */
    
    
    .contact-hero-wrapper{
        padding: 0vw 0 0vw 0;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .contact-form .cb{
        display: none;
    }
    
    
    /* Contact Form */


    .contact-form h4:not(.fromh4-1){
        margin: 6.452vw 0;
    }
    
    .contact-form form .p-i input, .contact-form textarea{
        font-size: 2.688vw;
        line-height: 3.226vw;
        padding: 2.151vw 0;
        margin: 6.452vw 0;
        min-width: 100%;
    }
    
    .contact-form textarea{
        min-height: 16.129vw;
    }
    
    .contact-form .w-s, .contact-form .e-b {
        max-width: 100%;
    }
    
    .contact-form label{
        padding: 2.151vw 3.226vw;
        font-size: 2.151vw;
        line-height: 2.688vw;
        margin: 1.613vw 0.806vw;
    }

    .contact-form label::before{
        display: none;
    }

    .contact-form label:hover{
        color: #000;
        border: 1px solid #000;
    }

    .contact-values  {
        padding-top: 13.441vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Services -----------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .services-hero-wrapper{
        padding: 0vw 0 0vw 0;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    
    .services-section2 .s-all:not(.s-wwa){
        margin-top: 13.441vw;
    }
    
    .services-section2 .s-all span.s-nu{
        margin-bottom: 1.613vw;
    }
    
    .services-section2 .s-all h5{
        font-size: 2.688vw;
        line-height: 4.301vw;
        margin-top: 4.301vw;
    }
    
    .services-section2 .s-all .s-rows{
        display: block;
        margin: 7.501vw 0 0 0;
    }
    
    .services-section2 .s-all .s-row{
        min-width: 100%;
    }
    
    .services-section2 .s-all hr{
        margin: 4.301vw 0;
    }  

    .services-section2 .s-all .button{
        margin: 4.301vw 0 0 0;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Studio --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .studio-hero-wrapper{
        padding: 0vw 0 0vw 0;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }


    .wwa-content{
        display: block;
        margin-top: 3.226vw;
        margin-bottom: 13.441vw;
    }

    .wwa-content p{
        margin-top: 3.226vw;
    }

    .studio-hwd{
        margin: 13.441vw 0;
    }

    .hwd-content{
        margin-top: 3.226vw;
        margin-bottom: 6.72vw;
    }

    .hwd-p-cover{
        padding: 3.226vw 3.226vw 3.226vw 0;
    }

    .hwd-p-cover .right {
        min-width: 26vw;
    }

    .hwd-p-cover span{
        font-size: 2.151vw;
        line-height: 3.226vw;
    }

    .studio-section2 h3, .studio-section2 h3 *{
        font-size: 3.226vw;
        line-height: 3.763vw;
    }

    .hwd-p-cover h3{
        margin-left: 5.376vw;
    }

    .hwd-p-cover li{
        font-size: 2.688vw;
        line-height: 3.763vw;
    }

    .studio-hwd .button-cover{
        text-align: center;
        margin-top: 3.226vw;
    }

    .studio-f-img{
        height: 50vh;
        background-position-x: center;
    }

    .s-values-1{
        margin: 13.441vw 0;
    }

    .studio-values-flex{
        display: block;
    }

    .studio-values-flex p, .studio-values .accordion-item p{
        font-size: 2.688vw;
        line-height: 3.763vw;
        margin-top: 2.151vw;
    }

    .studio-values-flex div{
        min-width: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 5.376vw;
    }

    .studio-values-flex1 div{
        margin-bottom: 5.376vw;
    } 

    .studio-career-cover{
        padding: 3.226vw 0;
    }

    
    .studio-faq .accordion-button {
        padding: 3.226vw 0;
    }
    
    .studio-faq .accordion-body {
        padding: 0 0 3.226vw 0;
    }

    
    .studio-faq .accordion {
        --bs-accordion-btn-icon-width: 2.8vw;
    }
    

    /* Experimental */

    .wwa-slider {
        position: relative;
        overflow: hidden;
    }
    
    .images-container {
        gap: 2.688vw;
        animation: ma 25s linear infinite;
    }
    
    
    @keyframes ma {
        0% {
        transform: translateX(0%);
        }
        100% {
        transform: translateX(-400%);
        }
    }
    
    .wwa-slider .image1 {
        width: 43.011vw;
        height: 38.038vw;
    }
    
    .wwa-slider .image2 {
        width: 43.011vw;
        height: 57.383vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Work --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .work-project .nav-tabs{
        margin-bottom: 0;
    }

    .work-project .button{
        margin-right: 2.151vw;
    }

    .work-flex{
        position: relative;
        overflow: hidden;
        display: block;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }



    .work-flex .inner-project{
        margin-top: 6.72vw;
        height: fit-content;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .work-flex .inner-project img{
        height: auto;
        width: 100%;
    }

    
    

  
  }
  
  
  /* --------------------------------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------- Mobiles ------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------- */
  
  @media (max-width: 743px) {

    .sub-s2 h2, .sub-s2 h2 * {
        font-size: 7.905vw;
        line-height: 10.256vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------- Extra Page -----------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .extra-page{
        background-image: url('https://ik.imagekit.io/onceadev/assets/tr:f-auto/exatra-page-bgm.png');
    }

    .extra-page p{
        margin: 4.154vw 0 6.154vw 0;
    }

    .extra-page .sb{
        margin-top: 3.226vw;
        margin-left: 0;
    }

    .extra-page svg{
        width: 90.100vw;
    }
  
        
    .sub-s2{
        padding: 15.385vw 0;
    }

    .sub-heading{
        font-size: 4.103vw;
        line-height: 5.641vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Contact --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .contact-hero-wrapper{
        padding: 0vw 0 0vw 0;
        min-height: 80vh;
        margin-bottom: -10vh;
    }
    

    /* Contact Form */


    .contact-form h4{
        font-size: 8.205vw; /* 32px - 390 */
        line-height: 10.769vw; /* 42px - 390 */
    }

    .contact-form h4:not(.fromh4-1){
        margin: 10.256vw 0;
    }

    .contact-form form .p-i input, .contact-form textarea{
        font-size: 4.103vw;
        line-height: 5.128vw;
        padding: 4.103vw 0;
        margin: 10.256vw 0;
        min-width: 100%;
    }

    .contact-form textarea{
        min-height: 30vw;
    }


    .contact-form label{
        padding: 4.103vw 6.154vw;
        font-size: 3.59vw;
        line-height: 4.872vw;
        margin: 2.051vw 1.026vw;
    }

    .contact-values  {
        padding-top: 15.385vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Services -----------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .services-hero-wrapper{
        min-height: 80vh;
        margin-bottom: -10vh;
    }
    
    
    .services-section2 .s-all:not(.s-wwa){
        margin-top: 15.385vw;
    }
    
    .services-section2 .s-all span.s-nu{
        margin-bottom: 3.077vw;
    }
    
    .services-section2 .s-all h5{
        font-size: 4.103vw;
        line-height: 6.154vw;
        margin-top: 6.154vw;
    }
    
    .services-section2 .s-all .s-rows{
        display: block;
        margin: 7.692vw 0 0 0;
    }
    
    .services-section2 .s-all .s-row{
        min-width: 100%;
    }
    
    .services-section2 .s-all hr{
        margin: 6.154vw 0;
    }  

    .services-section2 .s-all .button{
        margin: 7.692vw 0 0 0;
    }  

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Studio --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .studio-hero-wrapper{
        min-height: 80vh;
        margin-bottom: -10vh;
    }

    .studio-hero-wrapper h1{
        font-size: 10.282vw;
    }

    .wwa-content{
        display: block;
        margin-top: 3.077vw;
        margin-bottom: 15.385vw;
    }

    .wwa-content p{
        margin-top: 6.154vw;
    }

    .studio-hwd{
        margin: 15.385vw 0;
    }

    .hwd-content{
        margin-top: 3.077vw;
        margin-bottom: 12.308vw;
    }

    .hwd-p-cover{
        padding: 6.154vw 0;
        display: block;
    }

    .hwd-p-cover .left{
        display: block;
    }

    .hwd-p-cover .right {
        min-width: 100%;
    }

    .hwd-p-cover span{
        font-size: 3.59vw;
        line-height: 6.154vw;
        margin-bottom: 3.077vw;
        display: block;
    }

    .studio-section2 h3, .studio-section2 h3 *{
        font-size: 5.128vw;
        line-height: 7.179vw;
    }

    .hwd-p-cover h3{
        margin-left: 0;
        margin-bottom: 3.654vw;
    }

    .hwd-p-cover ul{
        padding-left: 3.828vw;
    }

    .hwd-p-cover li{
        font-size: 4.103vw;
        line-height: 7.179vw;
    }

    .studio-hwd .button-cover{
        text-align: center;
        margin-top: 6.154vw;
    }

    .studio-f-img{
        height: 50vh;
        background-position-x: center;
    }

    .s-values-1{
        margin: 15.385vw 0;
    }

    .studio-values-flex{
        display: block;
    }

    .studio-values-flex p, .studio-values .accordion-item p{
        font-size: 4.103vw;
        line-height: 6.154vw;
        margin-top: 3.654vw;
    }

    .studio-values-flex div{
        min-width: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10.256vw;
    }

    .studio-values-flex1 div{
        margin-bottom: 10.256vw;
    } 

    .studio-career-cover{
        padding: 6.154vw 0;
    }

    .studio-faq .accordion-button {
        padding: 6.154vw 0;
    }
    
    .studio-faq .accordion-body {
        padding: 0 0 6.154vw 0;
    }

    
    .studio-faq .accordion {
        --bs-accordion-btn-icon-width: 3.8vw;
    }

    /* Experimental */

    .wwa-slider {
        position: relative;
        overflow: hidden;
    }
    
    .images-container {
        gap: 3.077vw;
        animation: ma 15s linear infinite;
    }
    
    
    @keyframes ma {
        0% {
        transform: translateX(0%);
        }
        100% {
        transform: translateX(-400%);
        }
    }
    
    .wwa-slider .image1 {
        width: 61.538vw;
        height: 54.423vw;
    }
    
    .wwa-slider .image2 {
        width: 61.538vw;
        height: 82.103vw;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------- Work --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */
    /* .work-project .nav-tabs{
        overflow-x: scroll;
        width: max-content;
    } */

    .work-project .button{
        margin-right: 4.103vw;
        margin-bottom: 4.103vw;
    }

    .work-flex .inner-project{
        margin-top: 12.308vw;
    }

  }
  
  