/* --------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------- Tabs --------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width: 991px) {
    
    .work-page .sub-head{
        font-size: 2.688vw;
        line-height: 3.763vw;
        margin-bottom: 3.667vw;
    }
    
    .work-page h1{
        font-size: 5.376vw;
        line-height: 6.72vw;
    }
    
    .work-page h1 .line{
        height: 7vw;
    }
    
    .work-page .hero-content{
        margin-top: 26.882vw;
        margin-bottom: 13.441vw;
    }
    
    .work-page .work-hero-img-div{
        position: relative;
        overflow: visible;
        height: auto;
        border-radius: 0;
    }
    
    .work-page .work-page-hi{
        margin: 0;
        width: 100%;
        height: auto;
        border-radius: 1.613vw;
    }
    
    .work-page .work-inner-container{
        margin: 13.441vw 0;
    }
    
    .work-page .project-details{
        margin-top: 6.72vw;
    }
    
    .work-page .project-details .pd-flex{
        padding: 3.226vw 0;
    }
    
    .work-page .project-details h6{
        font-size: 2.688vw;
        line-height: 3.763vw;
    }
    
    .work-page .mock{
        margin: 13.441vw 0;
    }
    
    
    .work-page .mock .full-mockimg{
        border-radius: 1.613vw;
        height: 70vw;
    }
    
    .work-page .mock1 .full-mockimg{
        margin-bottom: 3.226vw;
    }
    
    .work-page .mock2 .full-mockimg{
        margin-top: 0vw;
    }
    
    .work-page .mock .mock-flex{
        display: block;
    }
    
    .work-page .mock .half-mockimg{
        margin: 3.226vw 0;
        border-radius: 1.613vw;
        width: 100%;
        height: 70vw;
    }

    .work-page .mock .half-mockimgin{
        margin-bottom: 3.226vw;
        border-radius: 1.613vw;
    }

    .work-page .related{
        margin: 13.441vw 0;
        padding-left: 4.032vw;
        padding-right: 4.032vw;
    }
    
    .work-page .related span{
        margin-bottom: 2vw;
    }
    
    .work-page .related h5{
        font-size: 5.376vw;
        line-height: 6.72vw;
        margin-bottom: 6.72vw;
    }
    
    .work-page .overflow-image img{
        height: 350px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    
    .work-page .related:hover .overflow-image img{
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- DESIGNLANE --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .designlane .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .designlane .full-tab{
        width: 74.74vw;
        height: auto;
        aspect-ratio: 845.9/611;
    }

    .designlane .half-tab{
        width: 74.74vw;
        height: auto;
        aspect-ratio: 540.75/390.59;
    }

    .designlane .half-mob{
        width: 30vw;
        height: auto;
        aspect-ratio: 328/664;
    }

    .designlane .full-length{
        width: 100%;
        height: auto;
        aspect-ratio: 1260/391;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- COINGUARD --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .coinguard .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .coinguard .threemorl{
        width: 90%;
        height: auto;
    }

    .coinguard .mock .half-mockimg{
        aspect-ratio: 447.5/450;
        width: 100%;
        height: auto;
    }

    .coinguard .widget{
        width: 80%;
        height: auto;
    }

    .coinguard .mobile1{
        width: 80%;
        height: auto;
    }

    .coinguard .half-mob{
        width: 80%;
        height: auto;
    }

    .coinguard .half-mob{
        width: 80%;
        height: auto;
    }

    .coinguard .mock .half-mockimg.l1{
        display: flex;
        align-items: flex-end;
    }

    .coinguard .mock .half-mockimg.l2{
        display: flex;
        align-items: flex-end;
    }

    .coinguard .mock .half-mockimg.l3{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- YUGA DECORS --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .yugadecors .mock .half-mockimg {
        width: 100%;
        height: 100%;
    }

    .yugadecors .mock .full-mockimg {
        height: auto;
    }

    .yugadecors .mock .half-mockimgin{
        width: 100%;
        height: auto;
    }

    .yugadecors .mobile1{
        width: 100%;
        height: auto;
    }

    .yugadecors .big{
        width: 100%;
        height: auto;
    }

    .yugadecors .big1{
        width: 100%;
        height: auto;
        aspect-ratio: 620/720;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- BOOKING VISTA --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .bookingvista .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .bookingvista .mock .full-mockimg {
        height: auto;
    }
    .work-page .mock .half-mockimg {
        height: auto;
    }

    .bookingvista .mobile1{
        width: 100%;
        aspect-ratio: 620/720;
    }

    .bookingvista .big{
        width: 100%;
        aspect-ratio: 1260/720;
    }





}
  
  
/* --------------------------------------------------------------------------------------------------------------------------------------
-------------------------------------------------------------- Mobiles ------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------- */
  
@media (max-width: 743px) {

    .work-page .sub-head{
        font-size: 4.103vw;
        line-height: 5.641vw;
        margin-bottom: 4vw;
    }
    
    .work-page h1{
        font-size: 7.605vw;
        line-height: 10.769vw;
    }
    
    .work-page h1 .line{
        height: 11vw;
    }
    
    .work-page .hero-content{
        margin-top: 40vw;
        margin-bottom: 10vw;
    }
    
    
    .work-page .work-page-hi{
        border-radius: 3.077vw;
    }
    
    .work-page .work-inner-container{
        margin: 15.385vw 0;
    }
    
    .work-page .project-details{
        margin-top: 12.308vw;
    }
    
    .work-page .project-details .pd-flex{
        padding: 6.154vw 0;
    }
    
    .work-page .project-details h6{
        font-size: 4.103vw;
        line-height: 5.641vw;
    }
    
    .work-page .mock{
        margin: 13.441vw 0;
    }
    
    
    .work-page .mock .full-mockimg{
        border-radius: 3.077vw;
        height: 85vw;
    }
    
    .work-page .mock1 .full-mockimg{
        margin-bottom: 6.154vw;
    }
    
    .work-page .mock2 .full-mockimg{
        margin-top: 0vw;
    }
    

    .work-page .mock .half-mockimg{
        margin: 6.154vw 0;
        border-radius: 3.077vw;
        width: 100%;
        height: 85vw;
    }

    .work-page .mock .half-mockimgin{
        margin-bottom: 6.154vw;
        border-radius: 3.077vw;
    }
    
    .work-page .related{
        margin: 13.441vw 0;
        padding-left: 5.128vw;
        padding-right: 5.128vw;
    }
    
    .work-page .related span{
        margin-bottom: 2vw;
    }
    
    .work-page .related h5{
        font-size: 7.605vw;
        line-height: 10.769vw;
        margin-bottom: -6.72vw;
    }
    
    .work-page .overflow-image img{
        object-fit: contain;
        object-position: top;
        height: auto;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    }
    
    .work-page .related:hover .overflow-image img{
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- DESIGNLANE --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .designlane .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .designlane .full-tab{
        width: 80vw;
        height: auto;
        aspect-ratio: 845.9/611;
    }

    .designlane .half-tab{
        width: 80vw;
        height: auto;
        aspect-ratio: 540.75/390.59;
    }

    .designlane .half-mob{
        width: 35vw;
        height: auto;
        aspect-ratio: 328/664;
    }

    .designlane .full-length{
        width: 100%;
        height: auto;
        aspect-ratio: 1260/391;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- COINGUARD --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .coinguard .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .coinguard .threemorl{
        width: 90%;
        height: auto;
    }

    .coinguard .mock .half-mockimg{
        width: 100%;
        height: auto;
    }

    .coinguard .widget{
        width: 80%;
        height: auto;
    }

    .coinguard .mobile1{
        width: 80%;
        height: auto;
    }

    .coinguard .half-mob{
        width: 80%;
        height: auto;
    }

    .coinguard .half-mob{
        width: 80%;
        height: auto;
    }

        /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- YUGA DECORS --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .yugadecors .mock .half-mockimg {
        width: 100%;
        height: 100%;
    }

    .yugadecors .mock .full-mockimg {
        height: auto;
    }

    .yugadecors .mock .half-mockimgin{
        width: 100%;
        height: auto;
    }

    .yugadecors .mobile1{
        width: 100%;
        height: auto;
    }

    .yugadecors .big1{
        width: 100%;
        height: auto;
        aspect-ratio: 620/720;
    }

    .yugadecors .big{
        width: 100%;
        height: auto;
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------- BOOKING VISTA --------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------- */

    .bookingvista .mock .half-mockimgin{
        width: 100%;
        height: 100%;
    }

    .bookingvista .mock .full-mockimg {
        height: auto;
    }
    .work-page .mock .half-mockimg {
        height: auto;
    }

    .bookingvista .mobile1{
        width: 100%;
        aspect-ratio: 620/720;
    }

    .bookingvista .big{
        width: 100%;
        aspect-ratio: 1260/720;
    }


}
  
  